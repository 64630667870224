<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue'
export default {
  data () {
    return {
      cdata: {
        category: [
        "郑州",
        "驻马店",
        "南阳",
        "许昌",
        "开封",
        "新乡",
        "焦作",
        "商丘",
        "登封",
        "濮阳",
        "平顶山",
        "信阳",
        ],
        lineData: [
          3545,
          3775,
          4351,
          5102,
          5635,
          5815,
          5878,
          6002,
          6410,
          6512,
          7801,
          8879,
        ],
        barData: [
          8879,
          7801,
          6512,
          6410,
          6002,
          5878,
          5815,
          5635,
          5102,
          4351,
          3775,
          3545
        ],
        rateData: []
      }
    };
  },
  components: {
    Chart,
  },
  mounted () {
    this.setData();
  },
  methods: {
    // 根据自己的业务情况修改
    setData () {
      for (let i = 0; i < this.cdata.barData.length -1; i++) {
        let rate = this.cdata.barData[i] / this.cdata.lineData[i];
        this.cdata.rateData.push(rate.toFixed(2));
      }
    },
  }
};
</script>

<style lang="scss" scoped>
</style>