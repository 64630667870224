<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: {
        xData: ["20次", "30次", "40次", "50次", "60次", "70次"],
        seriesData: [
          { value: 10, name: "20次",itemStyle: {
                    color: '#37a2da',
 
                  }, },
          { value: 5, name: "30次",itemStyle: {
                    color: '#32c5e9',
 
                  }, },
          { value: 15, name: "40次",itemStyle: {
                    color: '#9fe6b8',
 
                  }, },
          { value: 25, name: "50次",itemStyle: {
                    color: '#ffdb5c',
 
                  }, },
          { value: 20, name: "60次",itemStyle: {
                    color: '"#ff9f7f',
 
                  }, },
          { value: 35, name: "70次",itemStyle: {
                    color: '#fb7293',
 
                  }, }
        ]
      }
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>