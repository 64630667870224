<template>
  <div id="centerLeft1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-bar" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">今日告警</span>
          <dv-decoration-3 class="dv-dec-3" />
        </div>
      </div>
      <div class="d-flex jc-center">
        <CenterLeft1Chart />
      </div>
      <!-- 4个主要的数据 -->
      <div class="bottom-data">
        <div
          class="item-box mt-2"
          v-for="(item, index) in numberData"
          :key="index"
        >
          <div class="d-flex" style="margin-left: 20px">
            <span class="coin"></span>
            <dv-digital-flop class="dv-digital-flop" :config="item.number" />
          </div>
          <p class="text" style="text-align: center;">
            {{ item.text }}
            <span class="colorYellow">(次)</span>
          </p>
        </div>
      </div>
      <div class="d-flex jc-center body-box" style="margin-top: 70px">
        <dv-scroll-board class="dv-scr-board" :config="config" style="" />
      </div>
    </div>
  </div>
</template>

<script>
import CenterLeft1Chart from '@/components/echart/centerLeft/centerLeft1Chart'
export default {
  data() {
    return {
       config: {
        header: ['问题', '时间'],
        data: [
          [ '场地电压不稳定，美好人家电车充电桩3号', "<span  class='colorGrass'>08/13 09:11</span>"],
          [ '设备离线 升龙国际充电桩113-2号', "<span  class='colorRed'>08/13 10:30</span>"],
          [ '充电过程异常，用户V二用户', "<span  class='colorGrass'>08/13 11:51</span>"],
          [ '设备离线 升龙国际充电桩112-1号', "<span  class='colorGrass'>08/13 12:58</span>"],
          [ '设备离线 美好人家电车充电桩3号', "<span  class='colorGrass'>08/13 15:33</span>"],
          [ '设备离线 美好人家电车充电桩3号', "<span  class='colorGrass'>08/13 16:27</span>"],
          [ '设备离线 美好人家电车充电桩3号', "<span  class='colorGrass'>08/13 17:33</span>"],
          // [ '升龙国际中心汽车充电桩', "<span  class='colorRed'>36000</span>"],
          // [ '美好人家电车充电桩', "<span  class='colorRed'>36000</span>"],
          // [ '升龙国际中心汽车充电桩', "<span  class='colorGrass'>36000</span>"]
        ],
        rowNum: 4, //表格行数
        headerHeight: 35,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        index: true,
        columnWidth: [50],
        align: ['center']
      },
      numberData: [
        {
          number: {
            number: [12190],
            toFixed: 1,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '总计告警次数'
        },
        {
          number: {
            number: [104],
            toFixed: 1,
            textAlign: 'left',
            content: '{nt}',
            style: {
              fontSize: 24
            }
          },
          text: '今日告警次数'
        },
        // {
        //   number: {
        //     number: [361],
        //     toFixed: 1,
        //     textAlign: 'left',
        //     content: '{nt}',
        //     style: {
        //       fontSize: 24
        //     }
        //   },
        //   text: '正在编译数量'
        // },
        // {
        //   number: {
        //     number: [157],
        //     toFixed: 1,
        //     textAlign: 'left',
        //     content: '{nt}',
        //     style: {
        //       fontSize: 24
        //     }
        //   },
        //   text: '未通过数量'
        // }
      ]
    }
  },
  components: {
    CenterLeft1Chart
  },
  mounted() {
    this.changeTiming()
  },
  methods: {
    changeTiming() {
      setInterval(() => {
        this.changeNumber()
      }, 3000)
    },
    changeNumber() {
      this.numberData.forEach((item, index) => {
        item.number.number[0] += ++index
        item.number = { ...item.number }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$box-width: 300px;
$box-height: 410px;

#centerLeft1 {
  padding: 16px;
  height: $box-height;
  width: $box-width;
  border-radius: 10px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .dv-dec-3 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .bottom-data {
    .item-box {
      & > div {
        padding-right: 5px;
      }
      font-size: 14px;
      float: right;
      position: relative;
      width: 50%;
      color: #d3d6dd;
      .dv-digital-flop {
        width: 120px;
        height: 30px;
      }
      // 金币
      .coin {
        position: relative;
        top: 6px;
        font-size: 20px;
        color: #ffc107;
      }
      .colorYellow {
        color: yellowgreen;
      }
      p {
        text-align: center;
      }
    }
  }
  .body-box {
     overflow: hidden;
  }
   .dv-scr-board {
      width: 270px;
      height: 120px;
    }
}
</style>
