<template>
  <div id="centerRight1">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span>
          <icon name="chart-line" class="text-icon"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2">小区收益(月)</span>
        </div>
      </div>
      <div class="d-flex jc-center body-box">
        <dv-scroll-board class="dv-scr-board" :config="config" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ['项目名称', '总收益'],
        data: [
          [ '美好人家电车充电桩', "<span  class='colorGrass'>9300</span>"],
          [ '升龙国际中心汽车充电桩', "<span  class='colorRed'>8811</span>"],
          [ '美好人家电车充电桩', "<span  class='colorGrass'>7996</span>"],
          [ '升龙国际中心汽车充电桩', "<span  class='colorGrass'>7610</span>"],
          [ '美好人家电车充电桩', "<span  class='colorGrass'>7537</span>"],
          [ '升龙国际中心汽车充电桩', "<span  class='colorGrass'>7765</span>"],
          [ '美好人家电车充电桩', "<span  class='colorGrass'>8782</span>"],
          [ '升龙国际中心汽车充电桩', "<span  class='colorRed'>7625</span>"],
          [ '美好人家电车充电桩', "<span  class='colorRed'>5019</span>"],
          [ '升龙国际中心汽车充电桩', "<span  class='colorGrass'>5902</span>"]
        ],
        rowNum: 7, //表格行数
        headerHeight: 35,
        headerBGC: '#0f1325', //表头
        oddRowBGC: '#0f1325', //奇数行
        evenRowBGC: '#171c33', //偶数行
        index: true,
        columnWidth: [50],
        align: ['center']
      }
    }
  },
  components: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped>
$box-height: 410px;
$box-width: 300px;
#centerRight1 {
  padding: 16px;
  padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
    border-radius: 10px;
    overflow: hidden;
    .dv-scr-board {
      width: 270px;
      height: 340px;
    }
  }
}
</style>
